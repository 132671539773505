import * as Sentry from "@sentry/svelte";
import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'

const onError = clientInit(
    'https://612806f515946199eee670f155ed9593@o4506163189972992.ingest.sentry.io/4506163216842752',
    {
        sentryOptions: {
            // We recommend adjusting this value in production, or using tracesSampler
            tracesSampleRate: 1.0,

            // Optional: Initialize Session Replay:
            integrations: [new Sentry.Replay()],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        }
    },
)

export const handleError = onError((e) => {
    console.error('Error: ', e)
})